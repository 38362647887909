import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { darkColor } from "../../static/styles/colors"

const Video = ({ videoSrcURL, videoTitle, videoStyle }) => {
  const [acceptYoutubeCookie, setAcceptYoutubeCookie] = useState(false)
  const [cookie, setCookie] = useState("")
  const getCookie = name => {
    const value = `; ${cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(";").shift()
  }

  useEffect(() => {
    setCookie(document.cookie)
  }, [])

  if (getCookie("fhw360-cookie") === "true" || acceptYoutubeCookie) {
    return (
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        className="w-100 position-absolute"
        style={videoStyle}
      />
    )
  } else {
    return (
      <Button onClick={() => setAcceptYoutubeCookie(true)}>
        <div className="text-light text-center">
          Sie haben die Cookies für diese Website deaktiviert. Wenn Sie hier
          klicken, willigen Sie ein, dass das Youtube Video geladen wird und
          somit Daten zu Youtube in die USA übertragen werden. Weitere
          Informationen erhalten Sie in unserer{" "}
          <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link>.
        </div>
      </Button>
    )
  }
}
export default Video

const Button = styled.button`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-color: ${darkColor} !important;
  :hover {
    background-color: #303030 !important;
  }
  & > div {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
`
