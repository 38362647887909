import React from "react"

export const videoCaptionText = (
  <React.Fragment>
    Erklärt in 3 Minuten <br />
    ......sei schlau, mach's wie Michael!
  </React.Fragment>
)

export const customerReview = (
  <React.Fragment>
    Seien auch Sie bereit wie viele Ihrer Handwerkskollegen und verdienen
    endlich das, was Sie verdienen können!
  </React.Fragment>
)

export const customerVoices = (
  <React.Fragment>
    <div className="row my-4 justify-content-start">
      <div className="col-xl-2 col-md-0"></div>
      <div className="col-xl-5 col-md-8">
        <figure className="text-center">
          <blockquote className="text-success h3 mb-3">
            „Sehr interessant! Baustellen lassen sich auf dem Bierdeckel ruck
            zuck vor- und nachkalkulieren!“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Stefan Föller, Geschäftsführer, Föller GmbH, Schlüchtern
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-xl-5 col-md-4"></div>
      <div className="col-xl-5 col-md-8">
        <figure className="text-center">
          <blockquote className="text-success h3 mb-3">
            „Feedback in drei Worten: Besser geht's nicht!“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Ingo Welter, Geschäftsführer, Ingo Welter Sarl, Stadtbredimus
            (Luxemburg)
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-xl-6">
        <figure className="text-center">
          <blockquote className="text-success h3 mb-3">
            „Die Bierdeckelkalkulation ist noch einfacher anzuwenden, als ich
            erwartet hatte.“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Dieter Seitz, Inhaber, SEITZ DOMIZIL, Florstadt
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row my-4 justify-content-start">
      <div className="col-xl-2 col-md-0"></div>
      <div className="col-xl-5 col-md-8">
        <figure className="text-center">
          <blockquote className="text-success h3 mb-3">
            „Ein Redner aus der Praxis, der es mit plakativen Beispielen
            versteht, die Zuhörer von der Idee des Bierdeckels zu begeistern.“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Frank Neuss, Inhaber, Bedachungen Neuss, Eschweiler
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-xl-5 col-md-4"></div>
      <div className="col-xl-5 col-md-8">
        <figure className="text-center">
          <blockquote className="text-success h3 mb-3">
            „Herr Herber spricht die Sprache der Handwerker! Die
            Bierdeckelkalkulation ist ein wunderbares Controlling-Instrument!“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Max Leydecker, Inhaber, Leydecker e.K., Landau
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-xl-8">
        <figure className="text-center">
          <blockquote className="text-success h3 mb-3">
            „Klasse Vortrag! Mir wurde sehr schnell klar, wie wichtig es ist
            seine Preisuntergrenze zu kennen!“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Arnd Oberste-Dommes, Geschäftsführer, Oberste-Dommes Haustechnik
            GmbH, Kirchhundem
          </figcaption>
        </figure>
      </div>
    </div>
  </React.Fragment>
)

export const customerVoicesHERO = (
  <React.Fragment>
    <div className="row my-4 justify-content-start">
      <div className="col-xl-2 col-md-0"></div>
      <div className="col-xl-5 col-md-8">
        <figure className="text-center">
          <blockquote className="fs-InterMedium text-HERO-white h3 mb-3">
            „Sehr interessant! Baustellen lassen sich auf dem Bierdeckel ruck
            zuck vor- und nachkalkulieren!“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Stefan Föller, Geschäftsführer, Föller GmbH, Schlüchtern
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-xl-5 col-md-4"></div>
      <div className="col-xl-5 col-md-8">
        <figure className="text-center">
          <blockquote className="fs-InterMedium text-HERO-white  h3 mb-3">
            „Feedback in drei Worten: Besser geht's nicht!“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Ingo Welter, Geschäftsführer, Ingo Welter Sarl, Stadtbredimus
            (Luxemburg)
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-xl-6">
        <figure className="text-center">
          <blockquote className="fs-InterMedium text-HERO-white  h3 mb-3">
            „Die Bierdeckelkalkulation ist noch einfacher anzuwenden, als ich
            erwartet hatte.“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Dieter Seitz, Inhaber, SEITZ DOMIZIL, Florstadt
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row my-4 justify-content-start">
      <div className="col-xl-2 col-md-0"></div>
      <div className="col-xl-5 col-md-8">
        <figure className="text-center">
          <blockquote className="fs-InterMedium text-HERO-white  h3 mb-3">
            „Ein Redner aus der Praxis, der es mit plakativen Beispielen
            versteht, die Zuhörer von der Idee des Bierdeckels zu begeistern.“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Frank Neuss, Inhaber, Bedachungen Neuss, Eschweiler
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-xl-5 col-md-4"></div>
      <div className="col-xl-5 col-md-8">
        <figure className="text-center">
          <blockquote className="fs-InterMedium text-HERO-white  h3 mb-3">
            „Herr Herber spricht die Sprache der Handwerker! Die
            Bierdeckelkalkulation ist ein wunderbares Controlling-Instrument!“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Max Leydecker, Inhaber, Leydecker e.K., Landau
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-xl-8">
        <figure className="text-center">
          <blockquote className="fs-InterMedium text-HERO-white  h3 mb-3">
            „Klasse Vortrag! Mir wurde sehr schnell klar, wie wichtig es ist
            seine Preisuntergrenze zu kennen!“
          </blockquote>
          <figcaption className="blockquote-footer text-light fs-6">
            Arnd Oberste-Dommes, Geschäftsführer, Oberste-Dommes Haustechnik
            GmbH, Kirchhundem
          </figcaption>
        </figure>
      </div>
    </div>
  </React.Fragment>
)
